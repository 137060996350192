<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { saleSummaryListAPI, sumSaleSummaryListAPI } from "@/api/sale/report"; //销售报表

export default {
  name: "summary",
  dicts: ["bill_status"],
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: "53fa9473-7ab3-997d-106b-5fe8e89c9e1b",
        body: {},
        title: "销售汇总表",
        labelWidth: "80",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            tip:
              "单据编号、商品编号、商品名称、类别编号、类别名称、仓库编号、仓库名称、业务员编号、业务员名称、客户编号、客户名称",
            model: "",
            filter: "query",
          },
          this.$select({
            key: "billTypes",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "partnerFun",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurCreateEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurAuditEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: saleSummaryListAPI,
        getSummaryApi: sumSaleSummaryListAPI,
        tabsColumns: [
          {
            title: "客户汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "客户汇总查询",
            },
            uuid: "53fa9473-7ab3-997d-106b-5fe8e89c9e1b",
            defaultBody: { searchType: "partner" },
            type: "partner",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "billQty",
              "unitPriceTaxMoney",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "客户+仓库汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "客户+仓库汇总查询",
            },
            uuid: "59a2c0a0-9a23-7db2-fc6d-5222a5676a77",
            defaultBody: { searchType: "partnerStore" },
            type: "partnerStore",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "storeNo",
                label: "仓库编码",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "客户+商品分类汇总",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "客户+商品分类汇总",
            },
            uuid: "75de5163-a34c-995e-2cc3-b41c14dbb90f",
            defaultBody: { searchType: "partnerGoodsCategory" },
            type: "partnerGoodsCategory",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "partnerNo",
                label: "客户编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "客户名称",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "商品汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "商品汇总查询",
            },
            uuid: "b69c1b4f-79c0-3894-2179-a4b801b65a5f",
            defaultBody: { searchType: "goods" },
            type: "goods",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
              },
              {
                prop: "basUnitPrice",
                label: "基本单价",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "basUnitQty",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "商品类别汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "商品类别汇总查询",
            },
            uuid: "6f4f5b46-ac29-1341-4dad-513b1a82094e",
            defaultBody: { searchType: "goodsCategory" },
            type: "goodsCategory",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "业务员汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "业务员汇总查询",
            },
            uuid: "76295148-8abf-88cc-4c30-2fc41d08bf78",
            defaultBody: { searchType: "employee" },
            type: "employee",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "employeeNo",
                label: "业务员编码",
                minWidth: 150,
              },
              {
                prop: "employeeName",
                label: "业务员名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "仓库汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "仓库汇总查询",
            },
            uuid: "0e2abbc9-b863-3d07-d50b-5826b7af8dd5",
            defaultBody: { searchType: "store" },
            type: "store",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "storeNo",
                label: "仓库编码",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
          {
            title: "部门汇总查询",
            exportOption: {
              exportApi: "exportSaleSummary",
              exportName: "部门汇总查询",
            },
            uuid: "23d68ede-1289-1a3c-070b-e8d3be08f11f",
            defaultBody: { searchType: "dept" },
            type: "dept",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "deptNo",
                label: "部门编码",
                minWidth: 150,
              },
              {
                prop: "deptName",
                label: "部门名称",
                minWidth: 150,
              },
              {
                prop: "billQty",
                label: "销售数量",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
              {
                prop: "unitCostMoney",
                label: "成本金额",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
            ],
            summary: [
              "unitPriceTaxMoney",
              "billQty",
              "unitTaxMoney",
              "billNotaxMoney",
              "unitCostMoney",
            ],
          },
        ],
      },
    };
  },
};
</script>
